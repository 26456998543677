


@media (min-width: 1300px) and (orientation: landscape) {
    .shortletsCard-200 {
        margin-top: -200px;
    }
    
    .shortletsCard-100 {
        margin-top: -100px;
    }
    
    .shortletsCard-0 {
        margin-top: 0px;
    }
    
}