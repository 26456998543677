.react-calendar {
    width: 100% !important;
    max-width: 100%;
    background: white;
    border: 0px solid #a0a096 !important;
    font-family: Manrope !important;
    line-height: 1.125em;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform:none !important;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__tile--active {
    background: #FF5C5C !important;
    color: white;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 50%;
    height: 60px;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px !important;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
}