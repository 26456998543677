.home{
    background-image: url('../../../assets/images/BGlight.png');
}

@media (min-width: 1300px) and (orientation: landscape) {
    .shortletContainer {
        margin-top: -35vh;
    }
    
    .navContainer {
        height: 100vh;
    }
}