@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge, and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Smooth scrolling for all scrollable elements */
  html {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.containerAlt {
  margin-right: auto;
  margin-left: auto;
  max-width: 2100px;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carousel .flickity-button {
  display: none !important;
}

.carousel .flickity-page-dots {
  bottom: 10px;
}

.carousel .flickity-page-dots .dot {
  width: 8px;
  height: 8px;
}

@media (min-width: 768px) {
  .scrollbar-thin::-webkit-scrollbar {
    width: 0.7rem;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 10vh;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: #a3a3a3;
  }
}

.react-datepicker {
  width: 100% !important;
}

.react-datepicker__day-names {
  display: flex !important;
  justify-content: space-around !important;
}

.react-datepicker__week {
  display: flex !important;
  justify-content: space-between !important;
}

.react-datepicker__month-container {
  float: none !important;
  width: 100%;
}

.lagosBg {
  background-image: url("assets/images/africamap.png");
  background-size: 350%;
  background-blend-mode: lighten;
  background-position: -3.5rem -3.5rem;
  background-repeat: no-repeat;
}

.abujaBg {
  background-image: url("assets/images/africamap.png");
  background-size: 370%;
  background-blend-mode: lighten;
  background-position: -3.8rem -3.2rem;
  background-repeat: no-repeat;
}

.dubaiBg {
  background-image: url("assets/images/dubaimap.png");
  background-size: 300%;
  background-blend-mode: lighten;
  background-position: -4.7rem -3.7rem;
  background-repeat: no-repeat;
}

.react-tel-input .form-control {
  border-radius: 20px !important;
  width: 100% !important;
  padding: 4px 48px !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 19px 0 0 19px !important;
}
